<template>
  <v-app>
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-select
            v-model="cup_select"
            label="Pokal"
            dense
            outlined
            :items="cups"
            item-text="name"
            item-value="id"
            v-on:change="getRaces()"
          >
          </v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-select
            v-model="race_select"
            label="Tekma"
            v-if="cup_select"
            dense
            outlined
            :items="races"
            item-text="name"
            item-value="id"
            v-on:change="getCategories()"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-select
            v-model="category_select"
            v-if="race_select"
            label="Kategorija"
            dense
            outlined
            :items="categories"
            item-text="name"
            item-value="id"
            v-on:change="getResults()"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        dense
        v-if="results[0].twoRuns === false"
        :headers="headers"
        :items="results"
        item-key="name"
        class="elevation-2"
        hide-default-footer
      ></v-data-table>
      <v-data-table
        dense
        v-if="results[0].twoRuns === true"
        :headers="headersTwoRuns"
        :items="results"
        item-key="name"
        class="elevation-2"
        hide-default-footer
      ></v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data: () => ({
    cup_select: null,
    race_select: null,
    category_select: null,
    info: null,
    cups: null,
    races: null,
    categories: null,
    headers: [
      {
        text: "Mesto",
        align: "start",
        sortable: true,
        value: "place",
      },
      { text: "Ime in priimek", value: "name" },
      { text: "Štartna številka", value: "startnumber" },
      { text: "Čas", value: "fullTime" },
    ],
    headersTwoRuns: [
      {
        text: "Mesto",
        align: "start",
        sortable: true,
        value: "place",
      },
      { text: "Ime in priimek", value: "name" },
      { text: "Štartna številka", value: "startnumber" },
      { text: "Čas prvi tek", value: "firstRun" },
      { text: "Čas drugi tek", value: "secondRun" },
      { text: "Skupni čas", value: "fullTime" },
    ],
    results: [{ twoRuns: null }],
    polling: null,
  }),

  methods: {
    async getCups() {
      this.categories = null;
      this.results = [{ twoRuns: null }];
      this.race_select = null;
      this.category_select = null;
      axios
        .get("http://live.ski-javornik.si/cup")
        .then((response) => {
          this.cups = response.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    async getRaces() {
      this.results = [{ twoRuns: null }];
      this.race_select = null;
      this.category_select = null;
      axios
        .get("http://live.ski-javornik.si/cup/" + this.cup_select)
        .then((response) => {
          this.races = response.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    async getCategories() {
      this.results = [{ twoRuns: null }];
      this.categories = null;
      this.category_select = null;
      axios
        .get("http://live.ski-javornik.si/category/" + this.race_select)
        .then((response) => {
          this.categories = response.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    async getResults() {
      if (this.category_select == null) {
        return;
      }
      for (var key = 0; key < this.races.length; key++) {
        if (parseInt(key) === parseInt(this.race_select)) {
          if (this.races[key]["active"] == false) {
            return;
          }
        }
      }

      axios
        .get(
          "http://live.ski-javornik.si/race?race=" +
            this.race_select +
            "&category=" +
            this.category_select
        )
        .then((response) => {
          this.results = response.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    pollData() {
      this.polling = setInterval(() => {
        this.getResults();
      }, 3000);
    },
  },
  mounted() {
    this.getCups();
    this.pollData();
  },
};
</script>